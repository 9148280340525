import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import TagManager from "react-gtm-module";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from "react-intl";
import { redirectIfAuthenticated, setTracking } from "./utils/cookie";
import { RECAPTCHA_SITEKEY } from "./utils/constants";

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: "GTM-MR3BJL"
  };
  TagManager.initialize(tagManagerArgs);
}

try {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach((v, k) => {
    if (k !== "gclid" && !k.startsWith("utm_") && k !== "trial_type") {
      urlParams.delete(k);
    }
  });

  setTracking(urlParams);
} catch(err) {
  console.error("Impossible to read query params");
}


if (
  window.location.pathname !== "/" ||
  (window.location.pathname === "/" && !redirectIfAuthenticated())
) {
  // Define user's language. Different browsers have the user locale defined
  // on different fields on the `navigator` object, so we make sure to account
  // for these different by checking all of them
  const userLanguage =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
  // Split locales with a region code
  const languageWithoutRegionCode = userLanguage
    .toLowerCase()
    .split(/[_-]+/)[0];
  const supportedLanguages = ["en", "fr"];
  let language;
  if (supportedLanguages.indexOf(languageWithoutRegionCode) > -1) {
    language = userLanguage;
  } else {
    language = "en-US";
  }

  // Load only the language that we need
  let localeData;
  try {
    localeData = require(`./locales/${languageWithoutRegionCode}.json`);
  } catch (err) {
    // If the translation not exist
    localeData = require(`./locales/en.json`);
  }

  ReactDOM.render(
    <IntlProvider locale={language} messages={localeData}>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITEKEY}>
        <React.Suspense fallback={<div />}>
          <App />
        </React.Suspense>
      </GoogleReCaptchaProvider>
    </IntlProvider>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
