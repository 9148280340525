import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import { calcColumnWidth } from "../../utils/layout";
import { getSubmitConsentUrl } from "../../utils/api";

import Button from "../../components/Button";

const Layout = styled.div`
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  margin-top: 316px;
  margin-left: auto;
  margin-right: auto;
  width: ${() => calcColumnWidth(6, 6, "end")};

  button + button {
    margin-left: 32px;
  }

  @media only screen and (max-width: 940px) {
    margin-top: 250px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Title = styled.div`
  font-family: Moderat;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 33px;
  margin-bottom: 80px;
  text-align: center;

  @media only screen and (max-width: 940px) {
    font-size: 30px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled(Button)`
  border: 1px solid #c4bcc0;
  background: transparent;
  color: #145cc6;

  &:hover {
    background: transparent;
  }
`;

const Consent = ({ intl }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const client = urlParams.get("client");

  const accept = () => {
    document.getElementById("grant-input").value = "accepted";
  };

  return (
    <Layout>
      <Content>
        <Title>
          {intl.formatMessage(
            { id: "consent_description" },
            { client: client || process.env.REACT_APP_CLIENT_NAME }
          )}
        </Title>
        <form action={getSubmitConsentUrl()} method="post">
          <input type="hidden" name="csrf" value={urlParams.get("csrf")} />
          <input
            type="hidden"
            name="scope"
            value={urlParams.get("scope") || ""}
          />
          <input type="hidden" name="grant" id="grant-input" value="refused" />
          <Buttons>
            <Button width={calcColumnWidth(2, 3, "start")} onClick={accept} autoFocus>
              {intl.formatMessage({ id: "yes_authorize" })}
            </Button>
            <CancelButton width={calcColumnWidth(2, 3, "start")}>
              {intl.formatMessage({ id: "cancel" })}
            </CancelButton>
          </Buttons>
        </form>
      </Content>
    </Layout>
  );
};

export default injectIntl(Consent);
